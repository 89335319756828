import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import axiosInstance from '../utils/axiosInstance'; // Use the same Axios instance as App.js
import './Element.css'; // Custom styles for this component

const Element = ({ element }) => {
  const [elementDetails, setElementDetails] = useState(element || null); // Use passed data or fetch
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!element) {
      // Fetch element data if not provided as a prop
      axiosInstance
        .get(`elements/${element.atomic_number}/`)
        .then((response) => {
          setElementDetails(response.data);
        })
        .catch((error) => {
          setError('Failed to fetch element details. Please try again.');
          console.error('Error fetching element data:', error);
        });
    }
  }, [element]);

  if (error) return <p className="error-message">{error}</p>;

  if (!elementDetails) return <p>Loading...</p>;

  return (
    <div className="element-detail">
      <h2 className="element-title">
        {elementDetails.name} ({elementDetails.symbol})
      </h2>
      <div className="element-info">
        <div><strong>Atomic Number:</strong> {elementDetails.atomic_number}</div>
        <div><strong>Atomic Weight:</strong> {elementDetails.atomic_weight}</div>
        <div><strong>Category:</strong> {elementDetails.category}</div>
        <div><strong>Period:</strong> {elementDetails.period}</div>
        <div><strong>Group:</strong> {elementDetails.group}</div>
        <div><strong>Group Name:</strong> {elementDetails.group_name}</div>
        <div><strong>Density:</strong> {elementDetails.density} g/cm³</div>
        <div><strong>Melting Point:</strong> {elementDetails.melting_point} °C</div>
        <div><strong>Boiling Point:</strong> {elementDetails.boiling_point} °C</div>
        <div><strong>Atomic Radius:</strong> {elementDetails.atomic_radius} pm</div>
        <div><strong>Electronegativity:</strong> {elementDetails.electronegativity}</div>
        
                {elementDetails.wikipedia_link && (
          <a
            href={elementDetails.wikipedia_link}
            target="_blank"
            rel="noopener noreferrer"
            className="wikipedia-link"
          >
            More Info (Wikipedia)
          </a>
        )}
      </div>
      <button
        className="more-details-button"
        onClick={() => window.location.href = `/element/${elementDetails.atomic_number}`}
      >
        More Details
      </button>
    </div>
  );
};

// Define PropTypes to make the component reusable
Element.propTypes = {
  element: PropTypes.object, // Accept `element` as an optional prop
};

export default Element;

import React, { useState, useEffect, useRef } from 'react';
import axiosInstance from './utils/axiosInstance';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import './App.css';
import Sidebar from './components/Sidebar';
import TopNavbar from './components/TopNavbar';
import Element from './components/Element';
import ElementDetail from './components/ElementDetail';
import CompoundList from './components/CompoundList';
import Arrow from './components/Arrow';
import CompoundDetail from './components/CompoundDetail';
import Atomic from './components/Atomics'
import BottomBar from './components/BottomBar';


function App() {
  const [elements, setElements] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isSidebarOpen, setIsSidebarOpen] = useState(() => {
  const savedState = localStorage.getItem('isSidebarOpen');
    return savedState ? JSON.parse(savedState) : false;
  });
  const [activeElement, setActiveElement] = useState(null);
  const [grouping, setGrouping] = useState('category'); // Default grouping type
  const [showMetal, setShowMetal] = useState(false); // State to toggle arrow visibility
  const [showNonMetal, setShowNonMetal] = useState(false);
  const [showRad, setShowRad] = useState(false);
  const [showElnev, setShowElnev] = useState(false);
  const [showION, setShowION] = useState(false);
  const [showORB, setShowORB] = useState(false);
  
  const [nonMetalColor, setNonMetalColor] = useState('rgba(255, 123, 156, 0.7)'); // Soft pink with 50% transparency
  const [metalColor, setMetalColor] = useState('rgba(107, 203, 119, 0.7)'); // Soft green with 50% transparency
  const [electronegativityColor, setElectronegativityColor] = useState('rgba(83, 162, 190, 0.7)'); // Calm blue with 50% transparency
  const [ionizationColor, setIonizationColor] = useState('rgba(216, 140, 0, 0.7)'); // Deep golden brown with 50% transparency
  const [radiusColor, setRadiusColor] = useState('rgba(161, 127, 224, 0.7)'); // Soft lavender with 50% transparency
  
  const elementRef = useRef();
  const tableRef = useRef(null); // Create a ref for the periodic table
  const [refreshKey, setRefreshKey] = useState(Date.now()); // Key to force re-render on resize

  useEffect(() => {
    const intervalId = setInterval(() => {
      setRefreshKey(Date.now()); // Update the key based on current time
    }, 3000); // Update every 30 seconds
    return () => clearInterval(intervalId); // Clean up the interval on component unmount
  }, []);

  const toggleMetal = () => {
    setShowMetal((prev) => !prev); // Function to toggle arrow visibility
    setShowNonMetal(false);             // Ensure metal arrows are hidden
    setShowRad(false);               // Ensure radius arrows are hidden
    setShowElnev(false);             // Ensure electronegativity arrows are hidden
    setShowION(false);               // Ensure ionization arrows are hidden
  
  };
    const toggleNonMetal = () => {
    setShowNonMetal((prev) => !prev); // Function to toggle arrow visibility
    setShowMetal(false);             // Ensure metal arrows are hidden
    setShowRad(false);               // Ensure radius arrows are hidden
    setShowElnev(false);             // Ensure electronegativity arrows are hidden
    setShowION(false);               // Ensure ionization arrows are hidden
    
  };
  const toggleRad = () => {
    setShowRad((prev) => !prev); // Function to toggle arrow visibility
    setShowMetal(false);             // Ensure metal arrows are hidden
    setShowNonMetal(false);               // Ensure radius arrows are hidden
    setShowElnev(false);             // Ensure electronegativity arrows are hidden
    setShowION(false);               // Ensure ionization arrows are hidden
  
  };
  const toggleElenv = () => {
    setShowElnev((prev) => !prev); // Function to toggle arrow visibility
    setShowMetal(false);             // Ensure metal arrows are hidden
    setShowRad(false);               // Ensure radius arrows are hidden
    setShowNonMetal(false);             // Ensure electronegativity arrows are hidden
    setShowION(false);               // Ensure ionization arrows are hidden
  
  };
  const toggleION = () => {
    setShowION((prev) => !prev); // Function to toggle arrow visibility
    setShowMetal(false);             // Ensure metal arrows are hidden
    setShowRad(false);               // Ensure radius arrows are hidden
    setShowElnev(false);             // Ensure electronegativity arrows are hidden
    setShowNonMetal(false);               // Ensure ionization arrows are hidden
  };
  const toggleORB = () => {
    setShowORB((prev) => !prev); // Function to toggle arrow visibility
  };

  // Define grouping options
  const groupingOptions = {
    category: {
      colors: {
        1: '#FF6F61', // Alkali Metals
        2: '#FFD700', // Alkaline Earth Metals
        4: '#FF4500', // Post-transition Metals
        5: '#00BFFF', // Metalloids
        6: '#DA70D6', // Other Reactive Nonmetals
        7: '#808080', // Halogens
        8: '#2F4F4F', // Noble Gases
        9: '#4682B4', // Transition Metals
        10: '#9932CC', // Lanthanides
        11: '#FF1493', // Actinides
        12: '#A9A9A9', // Unknown Properties
      },
      labels: {
        1: 'Alkali Metals',
        2: 'Alkaline Earth Metals',
        4: 'Post-transition Metals',
        5: 'Metalloids',
        6: 'Other Reactive Nonmetals',
        7: 'Halogens',
        8: 'Noble Gases',
        9: 'Transition Metals',
        10: 'Lanthanides',
        11: 'Actinides',
        12: 'Unknown Properties',
      },
    },
    orbitalBlock: {
      colors: {
        s: '#1E90FF',
        p: '#FF7F50',
        d: '#6A5ACD',
        f: '#20B2AA',
      },
      labels: {
        s: 's-block',
        p: 'p-block',
        d: 'd-block',
        f: 'f-block',
      },
    },
  };

  const toggleSidebar = () => {
    setIsSidebarOpen((prev) => {
      const newState = !prev;
      localStorage.setItem('isSidebarOpen', JSON.stringify(newState));
      return newState;
    });
  };

  const handleGroupingChange = () => {
    const groupingKeys = Object.keys(groupingOptions);
    const currentIndex = groupingKeys.indexOf(grouping);
    const nextGrouping = groupingKeys[(currentIndex + 1) % groupingKeys.length];
    setGrouping(nextGrouping);
  };

  useEffect(() => {
    axiosInstance
      .get('elements/')
      .then((response) => {
        setElements(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error('There was an error fetching the elements:', error);
        setLoading(false)
      });
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (elementRef.current && !elementRef.current.contains(event.target)) {
        setActiveElement(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const renderElement = (element) => {
    const { colors } = groupingOptions[grouping];
    const colorKey = grouping === 'category' ? element.group : element.orbital_block;

    return (
      <div
        className="element-card"
        key={element.atomic_number}
        style={{
          backgroundColor: colors[colorKey] || '#CCCCCC', // Default color if key is not found
          gridRowStart: element.position_y,
          gridColumnStart: element.position_x,
        }}
      >
        <Link to={`/elements/${element.atomic_number}`} className="element-link">
          <div className="element-symbol">{element.symbol}</div>
          <div className="element-name">{element.name}</div>
          <div className="element-atomic-number">#{element.atomic_number}</div>
        </Link>
      </div>
    );
  };

  const renderLegend = () => {
    const { colors, labels } = groupingOptions[grouping];

    return (
      <div className="legend-in-grid">
        <div className="legend-header">
          <button onClick={handleGroupingChange} className="grouping-toggle">
            Orbital Blocks
          </button>
          <button
              onClick={toggleNonMetal}
              className="ionization-energy-toggle"
              style={{ color: nonMetalColor }}
            >
              Non-Metallic Behaviour Trend
            </button>
            <button
              onClick={toggleMetal}
              className="ionization-energy-toggle"
              style={{ color: metalColor }}
            >
              Metallic Behaviour Trend
            </button>
            <button
              onClick={toggleElenv}
              className="ionization-energy-toggle"
              style={{ color: electronegativityColor }}
            >
              Electron Affinity Trend
            </button>
            <button
              onClick={toggleION}
              className="ionization-energy-toggle"
              style={{ color: ionizationColor }}
            >
              Ionization Energy Trend
            </button>
            <button
              onClick={toggleRad}
              className="ionization-energy-toggle"
              style={{ color: radiusColor }}
            >
              Radius Trend
            </button>

        </div>
        <p>Click on the elements to see detailed information about them</p>
        <div className="legend-container">
          {Object.entries(colors).map(([key, color]) => (
            <div key={key} className="legend-item">
              <div
                className="legend-color-circle"
                style={{ backgroundColor: color }}
              ></div>
              <span className="legend-label">{labels[key]}</span>
            </div>
          ))}
        </div>
       
      </div>
    );
  };

  return (
    <Router>
      <div className={`App ${isSidebarOpen ? 'sidebar-open' : ''}`}>
        <TopNavbar />
        <button onClick={toggleSidebar} className="sidebar-toggle">
          <div className={`hamburger-icon ${isSidebarOpen ? 'open' : ''}`}>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </button>
        {isSidebarOpen && <Sidebar />}
        <div className={`allcomponents ${isSidebarOpen ? 'sidebar-open' : 'sidebar-closed'}`}>

          <Routes>
            <Route
              path="/"
              element={
                <>
                  
                  {loading ? (
                    <div className="loader"></div>
                  ) : (
                    <div className="periodic-table-wrapper" ref={tableRef} style={{ position: 'relative', overflowX: 'auto' }}>
                      <div className={`periodic-table`}>
                        {renderLegend()}
                        {elements.map((element) => renderElement(element))}
                      </div>
                      {(showNonMetal) && (  <Arrow key={refreshKey} x1={2} y1={8} x2={17} y2={3} tableRef={tableRef} color={nonMetalColor} />)}
                        {(showMetal) && (
                          <Arrow x1={17} y1={3} x2={2} y2={8} tableRef={tableRef} color={metalColor} />
                        )}
                        {(showElnev) && (
                          <Arrow x1={2} y1={2} x2={17} y2={2} tableRef={tableRef} color={electronegativityColor} />
                        )}
                        {(showElnev) && (
                          <Arrow x1={17} y1={9} x2={17} y2={2} tableRef={tableRef} color={electronegativityColor} />
                        )}
                        {(showION) && (
                          <Arrow x1={2} y1={2} x2={17} y2={2} tableRef={tableRef} color={ionizationColor} />
                        )}
                        {(showION) && (
                          <Arrow  x1={17} y1={9} x2={17} y2={2} tableRef={tableRef} color={ionizationColor} />
                        )}
                        {(showRad) && (
                          <Arrow x1={2} y1={2} x2={2} y2={9} tableRef={tableRef} color={radiusColor} />
                        )}
                        {showRad  && (
                          <Arrow x1={18} y1={9} x2={2} y2={9} tableRef={tableRef} color={radiusColor} />
                        )}

                    </div>
                  )}
                  {activeElement && (
                    <div className="element-popup" ref={elementRef}>
                      <Element element={activeElement} />
                    </div>
                  )}
                </>
              }
            />
            <Route path="/elements/:atomicNumber" element={<ElementDetail />} />
            <Route path="/compounds/:compoundname" element={<CompoundDetail />} />
            <Route path="/Compounds/" element={<CompoundList />} />
            <Route path="/AtomicTheories/" element={<Atomic />} /> 
          </Routes>
        </div>
        <BottomBar/>
      </div>
    </Router>
  );
}

export default App;

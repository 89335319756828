import React, { useEffect, useState } from 'react';
import axios from '../utils/axiosInstance';
import '../App.css';
import './Sidebar.css';

function Sidebar() {
    const [navLinks, setNavLinks] = useState([]);

    useEffect(() => {
        const fetchNavLinks = async () => {
            try {
                const response = await axios.get('nav-links/');
                setNavLinks(response.data);
            } catch (error) {
                console.error("Error fetching navigation links:", error);
            }
        };
        fetchNavLinks();
    }, []);

    return (
        <nav className="luxury-navbar">
            <ul className="sidebar-links">
                {navLinks.map((link) => (
                    <li key={link.id} className="sidebar-item">
                        <a href={link.url}>{link.title}</a>
                    </li>
                ))}
            </ul>
        </nav>
    );
}

export default Sidebar;


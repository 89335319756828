import React, { useEffect, useState } from 'react';
import './Arrow.css';

const Arrow = ({ x1, y1, x2, y2, color = '#000', tableRef }) => {
  const [arrowCoordinates, setArrowCoordinates] = useState({ startX: 0, startY: 0, endX: 0, endY: 0 });

  // Generate a unique ID for the arrowhead based on the coordinates
  const arrowId = `arrowhead-${x1}-${y1}-${x2}-${y2}-${Math.random().toString(36).substr(2, 9)}`;

  const calculateArrowCoordinates = () => {
    if (!tableRef.current) return;

    const tableWrapper = tableRef.current;
    const { scrollWidth, scrollHeight, scrollLeft, scrollTop } = tableWrapper;

    const cellWidth = scrollWidth / 18; // Assuming 18 columns
    const cellHeight = scrollHeight / 10; // Assuming 10 rows

    setArrowCoordinates({
      startX: (x1 - 1) * cellWidth ,
      startY: (y1 - 1) * cellHeight ,
      endX: (x2 - 1) * cellWidth,
      endY: (y2 - 1) * cellHeight,
    });
  };

  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      calculateArrowCoordinates();
    });

    if (tableRef.current) {
      resizeObserver.observe(tableRef.current);
    }

    // Add event listener for window resize and table scrolling
    window.addEventListener('resize', calculateArrowCoordinates);
    tableRef.current?.addEventListener('scroll', calculateArrowCoordinates);

    // Cleanup on unmount
    return () => {
      resizeObserver.disconnect();
      window.removeEventListener('resize', calculateArrowCoordinates);
      tableRef.current?.removeEventListener('scroll', calculateArrowCoordinates);
    };
  }, [x1, y1, x2, y2, tableRef]); // React to changes in props or the tableRef itself

  const { startX, startY, endX, endY } = arrowCoordinates;

  return (
    <svg
      className="arrow-overlay"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: `${tableRef.current?.scrollWidth || 0}px`,
        height: `${tableRef.current?.scrollHeight || 0}px`,
      }}
    >
      <defs>
        <marker
          id={arrowId}
          markerWidth="10"
          markerHeight="7"
          refX="8"
          refY="3"
          orient="auto"
        >
          <polygon points="0 0, 8 3, 0 7" fill={color} />
        </marker>
      </defs>
      <line
        x1={startX}
        y1={startY}
        x2={endX}
        y2={endY}
        stroke={color}
        strokeWidth="24"
        markerEnd={`url(#${arrowId})`} // Use the unique ID
      />
    </svg>
  );
};

export default Arrow;

import React from 'react';
import './BottomBar.css';

const BottomBar = () => {
  return (
    <div className="bottom-bar">
      <div className="bottom-bar-content">
        {/* Company Info */}
        <div className="bottom-bar-section">
             </div>

        {/* Quick Links */}
        <div className="bottom-bar-section">
          
          
        </div>

        {/* Contact Information */}
        <div className="bottom-bar-section">
          
        </div>

        {/* Social Media Links */}
        <div className="bottom-bar-section">
     
        </div>
      </div>

      {/* Copyright */}
      <div className="bottom-bar-footer">
        <p>&copy; {new Date().getFullYear()} Dahlia Aghaei. All rights reserved.</p>
      </div>
    </div>
  );
};

export default BottomBar;

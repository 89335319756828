import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom'; // For accessing the route parameters
import axiosInstance from '../utils/axiosInstance'; // Axios instance for API calls
import './Details.css'; // Custom styles
import GoBackButton from './GoBackButton'; // Back button component

const ElementDetail = () => {
  const { atomicNumber } = useParams(); // Extract atomic number from the route
  const [elementDetails, setElementDetails] = useState(null); // State to store element details
  const [error, setError] = useState(null); // State to store any error messages

  useEffect(() => {
    // Fetch element details from the API using the atomic number
    axiosInstance
      .get(`/elements/?atomic_number=${atomicNumber}`)
      .then((response) => {
        if (response.data.length > 0) {
          // Find the exact match for the atomic number
          const exactMatch = response.data.find(
            (element) => element.atomic_number === parseInt(atomicNumber, 10)
          );

          if (exactMatch) {
            setElementDetails(exactMatch);
          } else {
            setError(`No element found with atomic number ${atomicNumber}`);
          }
        } else {
          setError(`No element found with atomic number ${atomicNumber}`);
        }
      })
      .catch((error) => {
        setError('Failed to fetch element details. Please try again.');
        console.error('Error fetching element data:', error);
      });
  }, [atomicNumber]);

  if (error) return <p className="error-message">{error}</p>;

  if (!elementDetails) return <p>Loading...</p>;

  return (
    <div className="element-detail">
      <h2>
        {elementDetails.name} ({elementDetails.symbol})
      </h2>
      <div className="element-info">
        <div>
          <strong>Atomic Number:</strong> {elementDetails.atomic_number}
        </div>
        <div>
          <strong>Atomic Weight:</strong> {elementDetails.atomic_weight}
        </div>
        <div>
          <strong>Category:</strong> {elementDetails.category}
        </div>
        <div>
          <strong>Period:</strong> {elementDetails.period}
        </div>
        <div>
          <strong>Group:</strong> {elementDetails.group}
        </div>
        <div>
          <strong>Group Name:</strong> {elementDetails.group_name}
        </div>
        <div>
          <strong>Density:</strong> {elementDetails.density} g/cm³
        </div>
        <div>
          <strong>Melting Point:</strong> {elementDetails.melting_point} °C
        </div>
        <div>
          <strong>Boiling Point:</strong> {elementDetails.boiling_point} °C
        </div>
        <div>
          <strong>Atomic Radius:</strong> {elementDetails.atomic_radius} pm
        </div>
        <div>
          <strong>Electronegativity:</strong> {elementDetails.electronegativity}
        </div>
        <div>
          <strong>Ionization Energy:</strong> {elementDetails.ionization_energy}{' '}
          kJ/mol
        </div>
        <div>
          <strong>Radioactive:</strong>{' '}
          {elementDetails.is_radioactive ? 'Yes' : 'No'}
        </div>
        <div>
          <strong>Color:</strong> {elementDetails.color}
        </div>
        <div>
          <strong>Discovered By:</strong> {elementDetails.discovery_person}
        </div>
        <div>
          <strong>Discovered Year:</strong> {elementDetails.discovered_year}
        </div>
        <div>
          <strong>Isotopes:</strong> {elementDetails.isotope_info}
        </div>
        <div>
          <strong>Heat of Vaporization:</strong>{' '}
          {elementDetails.heat_of_vaporization} kJ/mol
        </div>
        <div>
          <strong>Abundance in Earth's Crust:</strong>{' '}
          {elementDetails.abundance_in_earth_crust} ppm
        </div>
        <div>
          <strong>Half-Life:</strong>{' '}
          {elementDetails.half_life
            ? `${elementDetails.half_life} years`
            : 'N/A'}
        </div>
        <div>
          <strong>Electron Configuration:</strong>{' '}
          {elementDetails.electron_configuration}
        </div>
        {elementDetails.wikipedia_link && (
          <a
            href={elementDetails.wikipedia_link}
            target="_blank"
            rel="noopener noreferrer"
          >
            More Info (Wikipedia)
          </a>
        )}
      </div>
      <GoBackButton />
    </div>
    
  );
};

export default ElementDetail;

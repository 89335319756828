import React, { useEffect, useState } from 'react';
import axios from '../utils/axiosInstance';
import './TopNavbar.css';
import { Link } from 'react-router-dom';
import { ReactComponent as AccountIcon } from '../assets/account-icon.svg';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import SignIn from './SignIn';

function TopNavbar() {
    const [siteInfo, setSiteInfo] = useState([]);

    useEffect(() => {
        const fetchSiteInfo = async () => {
            try {
                const response = await axios.get('site-info/');
                // Check if response.data is an array before setting it
                if (Array.isArray(response.data)) {
                    setSiteInfo(response.data);
                } else {
                    console.error('Expected an array but got:', response.data);
                }
            } catch (error) {
                console.error("Error fetching site info:", error);
            }
        };

        fetchSiteInfo();
    }, []);

    return (
        <div className="top-navbar">
            <div className="navbar-branding">
                <Link to="/signin" className="account-icon"></Link>
                {/* Wrap the branding in a Link to make it clickable */}
                <Link to="/" className="branding-link">
                    <h2 className="navbar-brand">Chemicopedia</h2>
                    <p className="navbar-motto">Discover, Learn, and Experiment in the World of Chemistry</p>
                </Link>
                <div className="navbar-actions">
                    <Link to="/signin" className="account-icon">
                        <AccountIcon />
                    </Link>
                </div>
            </div>

            <ul className="navbar-links">
                { siteInfo.map((link) => (
                        <li key={link.id || link.title}>
                            <a href={link.url}>{link.name}</a>
                        </li>
                    ))}
 
            </ul>
        </div>
    );
}

export default TopNavbar;

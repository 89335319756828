import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axiosInstance from '../utils/axiosInstance';
import './Compounds.css';
import "../App.css";
import GoBackButton from './GoBackButton'; // Back button component


function CompoundDetail() {
    const { compoundname } = useParams(); // Get the compound name from the URL
    const [compound, setCompound] = useState(null);
    const [categories, setCategories] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    useEffect(() => {
        const fetchCompound = async () => {
            try {
                const response = await axiosInstance.get('/compounds/');
                const selectedCompound = response.data.find((comp) => comp.name.toLowerCase() === compoundname.toLowerCase());
                if (!selectedCompound) {
                    setError(true);
                }
                setCompound(selectedCompound);
                setLoading(false);
            } catch (error) {
                console.error("Error fetching compound:", error);
                setLoading(false);
                setError(true);
            }
        };

        const fetchCategories = async () => {
            try {
                const response = await axiosInstance.get('/api/compoundscat/');
                setCategories(response.data);
            } catch (error) {
                console.error("Error fetching categories:", error);
            }
        };

        fetchCompound();
        fetchCategories();
    }, [compoundname]);

    const getCategoryNames = () => {
        return compound?.categories.map(
            (categoryId) => categories.find((cat) => cat.id === categoryId)?.name
        ).filter(Boolean).join(', ') || "None";
    };

    if (loading) return <div className="loader"></div>;
    if (error || !compound) return <div className="error-message">Compound not found.</div>;

    return (
        <div className="compound-detail">
            <h1 className="compound-detail-title">{compound.name}</h1>
            <div className="compound-details-wrapper">
            <div className="compound-images">
                    <p><strong>Lewis Structure:</strong> </p>
                    {compound.lewis_structure_image && (
                        <img
                            src={compound.lewis_structure_image}
                            alt={`Lewis structure of ${compound.name}`}
                            className="compound-image"
                        />
                    )}
                </div>
                <div className="compound-info">
                    <p><span className="hover-effect"><strong>Order:</strong> {compound.order}</span></p>
                    <p><span className="hover-effect"> <strong>Formula:</strong> {compound.formula}</span></p>
                    <p><span className="hover-effect"> <strong>IUPAC Name:</strong> {compound.iupac_name}</span></p>
                    <p><span className="hover-effect"> <strong>Molar Mass:</strong> {compound.molar_mass} g/mol</span></p>
                    <p><span className="hover-effect"> <strong>State at Room Temperature:</strong> {compound.state_at_room_temp}</span></p>
                    <p><span className="hover-effect"> <strong>Melting Point:</strong> {compound.melting_point} °C</span></p>
                    <p><span className="hover-effect"><strong>Boiling Point:</strong> {compound.boiling_point || "N/A"} °C</span></p>
                    <p><span className="hover-effect"> <strong>Density:</strong> {compound.density} g/cm³</span></p>
                    <p><span className="hover-effect"> <strong>Charge:</strong> {compound.charge}</span></p>
                    <p><span className="hover-effect"> <strong>Polarity:</strong> {compound.polarity || "N/A"}</span></p>
                    <p><span className="hover-effect"> <strong>Categories:</strong> {getCategoryNames()}</span></p>
                    <p><span className="hover-effect"> <strong>Description:</strong> {compound.description}</span></p>
                    <p>
                    <span className="hover-effect"> <strong>More Info:</strong> <a href={compound.wikipedia_link} target="_blank" rel="noopener noreferrer">Wikipedia</a>
                    </span></p>
                </div>
            </div>
            <GoBackButton />
        </div>
    );
}

export default CompoundDetail;
